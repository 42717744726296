import React from 'react'
import { navigate } from 'gatsby'
// custom
import { isLoggedIn } from 'src/components/auth/utils'

const PrivateRoute = ({ component: Component, location, ...rest }) => {
  if (!isLoggedIn()) {
    navigate('/login')
  }

  return <Component {...rest} />
}

export default PrivateRoute
