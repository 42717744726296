import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import FadeIn from 'react-fade-in'
import isEmpty from 'lodash/isEmpty'
import { css } from '@emotion/core'
// custom
import firebase from 'src/components/firebase/init'
import Spinner from 'src/components/view/loading'
import soundLike from 'src/sound/like.wav'

function Tickets({ isAdmin, pathname }) {
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    firebase
      .database()
      .ref(`${pathname}/tickets/served`)
      .on('value', snapshot => {
        setData(snapshot.val())
        setLoading(false)
      })
  }, [pathname])

  return (
    <div
      className={clsx({
        'd-flex flex-wrap justify-content-center overflow-auto text-center w-100': true,
        'align-items-center ': loading,
      })}
      style={{
        flex: isAdmin && loading ? 'auto' : 'initial',
        width: !isAdmin && loading ? '100vw' : 'inherit',
        height: !isAdmin && loading ? '100vh' : 'inherit',
      }}
    >
      <Spinner active={loading} />
      {/* empty ticket */}
      {!loading && isEmpty(data) && (
        <FadeIn delay={500}>
          <h3 className="w-100 text-center mt-5 w-100 text-white">No ticket</h3>
        </FadeIn>
      )}
      {/* show ticket */}
      {data &&
        Object.keys(data).map((item, index) => (
          <div
            key={index}
            className="p-3 m-3 position-relative text-white _theme-bg rounded-lg d-flex justify-content-center  align-items-center"
            css={css`
              @media (max-width: 374px) {
                font-size: 3rem;
                min-width: 8rem;
                max-height: 8rem;
              }
              font-size: 4rem;
              min-width: 9.5rem;
              max-height: 9.5rem;
              user-select: none;
            `}
          >
            <span>{data[item]}</span>
            {/* ❌ close button */}
            {isAdmin && (
              <button
                className="position-absolute pl-2 pr-2"
                css={css`
                  border: 0;
                  background: none;
                  right: 0;
                  top: 0;
                  font-size: 30px;
                  opacity: 0.8;
                  &:focus {
                    outline: none;
                  }
                `}
                onClick={() => {
                  const submit = new Audio(soundLike)
                  submit.play()
                  firebase
                    .database()
                    .ref(`${pathname}/tickets/served/${item}`)
                    .remove()
                }}
                alt={item}
              >
                <svg
                  width="1em"
                  height="1em"
                  viewBox="0 0 16 16"
                  className="bi bi-x"
                  fill="white"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
                  />
                </svg>
              </button>
            )}
          </div>
        ))}
    </div>
  )
}

export default Tickets
