import React from 'react'
import Container from 'react-bootstrap/container'
import Row from 'react-bootstrap/row'
import { useParams } from '@reach/router'
import get from 'lodash/get'
// cusotm
import ButtonAdmin from 'src/components/button/admin'
import CompanyName from 'src/components/common/company-name'
import SEO from 'src/components/view/seo'
import Tickets from 'src/components/pages/dashboard/tickets'
import { isLoggedIn } from 'src/components/auth/utils'

function UserDashboard() {
  const pathname = get(useParams(), 'companyPathname')

  return (
    <>
      <SEO title="Dashboard" />
      <Container className="d-flex d-flex flex-column p-0 bg-dark" fluid>
        <Row className="fixed-top position-sticky p-0">
          <h2 className="w-100 p-3 mb-0 text-center text-white text-uppercase bg-secondary">
            <CompanyName />
          </h2>
        </Row>
        <Row>
          <Tickets pathname={pathname} isAdmin={false} />
        </Row>
        {isLoggedIn() ? <ButtonAdmin /> : <></>}
      </Container>
    </>
  )
}

export default UserDashboard
