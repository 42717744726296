import { useEffect, useState } from 'react'
import firebase from 'src/components/firebase/init'
import get from 'lodash/get'
// custom
import { getCurrentCompanyKey } from 'src/components/auth/utils'

function CompanyName() {
  const [name, setName] = useState('⏳ loading ...')

  useEffect(() => {
    const companyKey = getCurrentCompanyKey()
      ? getCurrentCompanyKey()
      : 'example-url'

    firebase
      .database()
      .ref(`${companyKey}/info/names`)
      .once('value', snapshot => {
        setName(get(snapshot.val(), 'en', '😦 nameless'))
      })
  }, [])

  return name
}

export default CompanyName
