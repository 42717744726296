import soundMsn from 'src/sound/msn.mp3'
import soundTyping from 'src/sound/typing.wav'
import isEmpty from 'lodash/isEmpty'

export const speak = (messages, lang = 'zh') => {
  if (isEmpty(messages)) {
    return
  }
  // https://webplatformcourse.com/bonus/speech-synthesis-api/
  const voices = window.speechSynthesis.getVoices()
  let msg = new SpeechSynthesisUtterance(messages)
  msg.lang = lang === 'zh' ? 'zh-HK' : 'en-GB'

  if (lang === 'zh') {
    msg.voice = voices[64] // HK voice
    msg.text = `${msg.text} 號請到櫃面取多謝`
  } else {
    msg.voice = voices[0]
    msg.text = `${msg.text} is available now`
  }
  window.speechSynthesis.speak(msg)
}

export const playSound = item => {
  const typing = new Audio(soundTyping)
  const submit = new Audio(soundMsn)

  if (item === '🛎️') {
    submit.play()
    return
  }
  typing.play()
}
