import React from 'react'
import Button from 'react-bootstrap/button'
import { css } from '@emotion/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUnlock } from '@fortawesome/free-solid-svg-icons'
// custom
import { navigate } from 'gatsby'
import { logout } from 'src/components/auth/utils'

function ButtonLogout() {
  return (
    <Button
      variant="danger"
      className="position-absolute _button-opacity"
      css={css`
        left: 20px;
      `}
      onClick={() => logout(() => navigate(`/login`))}
    >
      <FontAwesomeIcon icon={faUnlock} />
    </Button>
  )
}

export default ButtonLogout
