import React from 'react'
import Button from 'react-bootstrap/button'
import { css } from '@emotion/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalculator } from '@fortawesome/free-solid-svg-icons'
// custom
import { navigate } from 'gatsby'

function ButtonAdmin() {
  return (
    <Button
      variant="primary"
      className="position-fixed d-flex rounded-circle justify-content-center p-3 _button-opacity"
      css={css`
        right: 20px;
        bottom: 20px;
      `}
      onClick={() => navigate(`/app/admin`)}
    >
      <FontAwesomeIcon icon={faCalculator} />
    </Button>
  )
}

export default ButtonAdmin
