import React, { useEffect, useState } from 'react'
import Row from 'react-bootstrap/row'
import clsx from 'clsx'
import { Link } from 'gatsby'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
// cusotm
import ButtonLogout from 'src/components/button/logout'
import Spinner from 'src/components/view/loading'
import { getCompanyKeys } from 'src/components/auth/utils'
import firebase from 'src/components/firebase/init'
import UserForm from 'src/components/pages/account/user-form'
import { userConfig } from 'src/components/pages/account/config'

function UserHome() {
  const [loading, setLoading] = useState(false)
  const [items, setItems] = useState([])

  useEffect(() => {
    setLoading(true)

    firebase
      .database()
      .ref()
      .on('value', snapshot => {
        const companyKey = get(snapshot.val(), getCompanyKeys(true))
        const companyInfo = get(companyKey, 'info')
        if (companyInfo) {
          setItems(companyInfo)
        }
        setLoading(false)
      })
  }, [])

  return (
    <>
      <h1 className="w-100 p-3 text-center text-white text-uppercase bg-secondary">
        <ButtonLogout />
        User Home
      </h1>
      <h2 className="pt-5 text-center text-capitalize text-white">
        update my info
      </h2>
      <UserForm type="update" items={userConfig} />
      <Row
        className={clsx({
          'd-flex flex-wrap justify-content-center overflow-auto text-center': true,
          'align-items-center ': loading,
        })}
      >
        <div>
          <hr />
          <h2 className="pt-5 text-center text-capitalize text-white">
            dashboard list
          </h2>
          <Spinner active={loading} />
          <ul>
            {!isEmpty(items) && (
              <li>
                <Link to="/app/admin">
                  <h3>{items.names.en}</h3>
                </Link>
              </li>
            )}
          </ul>
        </div>
      </Row>
    </>
  )
}

export default UserHome
