import React, { useState } from 'react'
import Button from 'react-bootstrap/button'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/container'
import firebase from 'src/components/firebase/init'
import Row from 'react-bootstrap/row'
import size from 'lodash/size'
import { css } from '@emotion/core'
// custom
import { playSound } from 'src/components/pages/dashboard/utils'

function Keypad({ pathname }) {
  const [ticket, setTicket] = useState(null)
  const CLEAR = 'x'
  const CALL = '🛎️'
  const BACKSPACE = '⬅️'
  const line1 = ['1', '2', '3']
  const line2 = ['4', '5', '6']
  const line3 = ['7', '8', '9']
  const line4 = [CLEAR, '0', CALL]
  const fontSize = `1.5rem`

  const handleDisable = val => {
    const numberIsThreeDigi =
      [CALL, CLEAR].indexOf(val) < 0 && size(ticket) === 3
    const zeroZero = val === '0' && ticket === '00'
    const callZero = val === CALL && (!ticket || parseInt(ticket) === 0)
    const clearInit = val === CLEAR && ticket === null

    return numberIsThreeDigi || zeroZero || callZero || clearInit
  }
  const handleDel = () => {
    if (size(ticket)) {
      playSound(BACKSPACE)
      setTicket(ticket.slice(0, -1))
    }
  }
  const handleClick = item => {
    playSound(item)
    if (item === CLEAR) {
      setTicket(null)
      return
    }
    // handleSubmit
    if (item === CALL) {
      pushToDatabase('served')
      return
    }
    setTicket(ticket === null ? item : ticket + item)
  }

  const pushToDatabase = table => {
    firebase
      .database()
      .ref(`${pathname}/tickets/${table}/${Date.now()}`)
      .set(ticket)
    setTicket(null)
  }

  const Buttons = ({ item }) =>
    item.map((val, index) => (
      <Col key={index} className="p-1">
        <Button
          variant="light"
          className="text-center w-100 shadow-sm bg-dark text-white position-relative"
          css={css`
            font-size: ${fontSize};
            &:hover,
            &:active {
              top: 1px;
            }
          `}
          onClick={() => handleClick(val)}
          disabled={handleDisable(val)}
          value={val}
        >
          {/* btn: CLEAR */}
          {val === CLEAR && (
            <svg
              width="1em"
              height="1em"
              viewBox="0 0 16 16"
              className="bi bi-x"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
              />
            </svg>
          )}
          {/* btn: number & submit */}
          {val !== CLEAR && val}
        </Button>
      </Col>
    ))

  return (
    <Container
      className="p-0 w-100"
      style={{
        maxWidth: `300px`,
        borderTop: `3px solid lightgray`,
        boxShadow: `0px -1px 3px 1px rgba(0, 0, 0, 0.2)`,
        backgroundColor: `lightgray`,
      }}
    >
      {/* line 1 */}
      <Row noGutters className="bg-white" css={{ height: `3rem` }}>
        <Col xs={8}>
          <h2 className="h-100 m-0 pl-3" css={{ lineHeight: `inherit` }}>
            {ticket}
          </h2>
        </Col>
        <Col xs={4} className="p-0 text-right">
          {/* backspace */}
          <Button
            variant="light"
            className="h-100 w-100 border-0 bg-transparent bg-light"
            css={css`
              :disabled {
                opacity: 0.3;
              }
            `}
            style={{ fontSize: fontSize }}
            onClick={() => handleDel()}
            disabled={!ticket}
          >
            <svg
              width="1em"
              height="1em"
              viewBox="0 0 16 16"
              className="bi bi-backspace"
              fill="black"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M6.603 2h7.08a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1h-7.08a1 1 0 0 1-.76-.35L1 8l4.844-5.65A1 1 0 0 1 6.603 2zm7.08-1a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-7.08a2 2 0 0 1-1.519-.698L.241 8.65a1 1 0 0 1 0-1.302L5.084 1.7A2 2 0 0 1 6.603 1h7.08zM5.829 5.146a.5.5 0 0 0 0 .708L7.976 8l-2.147 2.146a.5.5 0 0 0 .707.708l2.147-2.147 2.146 2.147a.5.5 0 0 0 .707-.708L9.39 8l2.146-2.146a.5.5 0 0 0-.707-.708L8.683 7.293 6.536 5.146a.5.5 0 0 0-.707 0z"
              />
            </svg>
          </Button>
        </Col>
      </Row>
      {/* line 2+ */}
      <Row noGutters>
        <Buttons item={line1} className="p-0" style={{ fontSize: fontSize }} />
      </Row>
      <Row noGutters>
        <Buttons item={line2} />
      </Row>
      <Row noGutters>
        <Buttons item={line3} />
      </Row>
      <Row noGutters>
        <Buttons item={line4} />
      </Row>
    </Container>
  )
}

export default Keypad
