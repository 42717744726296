import React from 'react'
import { Link } from 'gatsby'
// cusotm
import ButtonLogout from 'src/components/button/logout'
import CompanyName from 'src/components/common/company-name'
import Keypad from 'src/components/pages/dashboard/admin/keypad'
import SEO from 'src/components/view/seo'
import Tickets from 'src/components/pages/dashboard/tickets'
import { getCurrentCompanyKey } from 'src/components/auth/utils'

function AdminDashboard() {
  const pathname = getCurrentCompanyKey()

  return (
    <>
      <SEO title={`Admin Dashboard`} />
      <h2 className="w-100 p-3 mb-0 text-center text-white text-uppercase _theme-bg">
        <ButtonLogout />
        <Link to={`/app/dashboard/${pathname}`}>
          <CompanyName />
        </Link>
        {` `}
        <span>admin</span>
      </h2>
      <Tickets pathname={pathname} isAdmin={true} />
      <Keypad pathname={pathname} />
    </>
  )
}

export default AdminDashboard
