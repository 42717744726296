import React from 'react'
import { Router } from '@reach/router'
// custom
import Layout from 'src/components/view/layout'
import PrivateRoute from 'src/components/auth/private-route'
// components
import DashboardAdmin from 'src/components/pages/dashboard/admin/'
import DashboardUser from 'src/components/pages/dashboard/guest/'
import UserHome from 'src/components/pages/account/user-home'

function App() {
  return (
    <Layout>
      <Router>
        <PrivateRoute path="/app/admin" component={DashboardAdmin} />
        <PrivateRoute path="/app/home" component={UserHome} />
        <DashboardUser path="/app/dashboard/:companyPathname" />
      </Router>
    </Layout>
  )
}

export default App
