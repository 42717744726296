import React from 'react'
import Spinner from 'react-bootstrap/Spinner'

function Loading({ active }) {
  if (!active) {
    return <></>
  }
  return (
    <Spinner
      animation="border"
      role="status"
      style={{ width: `10rem`, height: `10rem` }}
    >
      <span className="sr-only">Loading...</span>
    </Spinner>
  )
}

export default Loading
